function DatatableGrid(element, options) {
    this.defaults = {
        columns_defs : [],
        has_filter: false,
        filters_list: [],
        order_by_col: [1, "asc"],
        server_side: false,
        url_req_method: null,
        url: null,
        csrf_token: null,
        page_length: 25,
        custom_btn_list: [],
        extra_datatable_callback: null,
    }

    this.has_html_rendered = false;
    this.element = element;
    this.element_id = $(this.element).attr('id');
    this.datatable = null;
    this.filter_instance = null;
    this.settings = $.extend(true, this.defaults, options);

    this.default_btns = {
        "upload_csv": {
            tooltip: 'Upload CSV',
            btn_id: 'upload_csv_btn',
            btn_icon: 'fas fa-angle-double-up'
        },
        "download_csv_template": {
            tooltip: 'Download CSV Template',
            btn_id: 'download_csv_template_btn',
            btn_icon: 'fas fa-angle-double-down'
        },
        "download_csv": {
            tooltip: 'Download CSV',
            btn_id: 'download_csv_btn',
            btn_icon: 'fas fa-cloud-download-alt'
        }
    }

    try{
        if (this.settings.filters_list.length > 0){
            this.settings.has_filter = true;
        }
    }catch(e){
        console.log(e);
    }

    try{
        if (this.settings.url !== null){
            this.settings.server_side = true;
        }
    }catch(e){
        console.log(e);
    }

    this.RenderDatatableHtml();
    this.InitDatatable()
    if (this.settings.has_filter === true){
        this.InitDatatableFilter();
    }
}

DatatableGrid.prototype.RenderDatatableHtml = function() {
    let html = ``;
    let grid_col = 12
    let filter_col = 3
    try{
       if (Array.isArray(this.settings.columns_defs)) {
           if (this.settings.columns_defs.length > 0) {
               if (this.settings.has_filter === true){
                   grid_col = 9
               }
               html +=
                   `<div class="row">
                        <div class="col-${grid_col}" id="data_table_grid_${this.element_id}_col" style="">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card-box p-2 pr-3 pl-3 mb-0">
                                        <table
                                            id="data_table_grid_${this.element_id}"
                                            class="table  activate-select dt-responsive nowrap"
                                            style="position:relative;width:100%; text-align: left"
                                        >
                                            <thead class="thead ">
                                                <tr>`;
               $.each(this.settings.columns_defs, function(i, e){
                    try{
                        html += `<th ${i===0?"scope='col'": ""}
                                    data-data="${e.data}"
                                    ${e.type? "data-type=${e.type}" : ""}
                                    data-name="${e.data}"
                                    data-format="${e.format}"
                                    class="${e.data}"
                                    style= "background-color: ${e.background}; vertical-align: middle; ${e.cell_width !== null? `max-width: ${e.cell_width}` : ''}"
                                >
                                ${e.label}
                                </th>
                                `;
                    }catch(e){
                        console.log(e)
                    }
               })
               html +=`
                                        </tr>
                                    </thead>
                                  </table>
                                </div>
                            </div>
                        </div>
                    </div>
               `;
               if (this.settings.has_filter === true){
                   html += `<div class="col-3" id="data_table_grid_${this.element_id}_filters" style=""></div>`;
               }
               html+=`
                </div>`;
               this.has_html_rendered = true;
           }
       }
    }catch(e){
        console.log(e);
    }
    $(this.element).append(html);
};

DatatableGrid.prototype.InitDatatable = async function() {
    const thisClass = this;
    try{
        if (this.has_html_rendered === true){
            $.each(this.settings.columns_defs, function (i, e) {
                try {
                    if ('render_function' in e){
                        if (e.render_function){
                            eval(e.render_function)
                        }
                    }
                    if (e.render) {
                        let render_value = e.render
                        e.render = eval(render_value);
                    }
                    if ('created_cell_function' in e){
                        if (e.created_cell_function){
                            eval(e.created_cell_function)
                        }
                    }
                    if ('createdCell' in e){
                        if (e.createdCell){
                            let createdCell = e.createdCell
                            e.createdCell = eval(createdCell)
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            })
            try {
                this.datatable = $(`#data_table_grid_${this.element_id}`).DataTable({
                    serverSide: thisClass.settings.server_side,
                    processing: true,
                    language: {
                        'loadingRecords': '&nbsp;',
                        'processing': '<div class="spinner-border avatar-sm text-primary" style="color: #9a90e9" role="status"></div>',
                        "paginate": {
                            "previous": "<i class='mdi mdi-chevron-left'>",
                            "next": "<i class='mdi mdi-chevron-right'>",
                        },
                        search: "_INPUT_",
                        searchPlaceholder: "Search...",
                    },
                    drawCallback: function () {
                        $(`#data_table_grid_${thisClass.element_id}_wrapper .dataTables_filter`).addClass('text-left');
                        $(`#data_table_grid_${thisClass.element_id}_wrapper .dt-buttons`).addClass('float-left');
                        $(`#data_table_grid_${thisClass.element_id}_wrapper .dataTables_filter label`).addClass('m-0')
                        $(`#data_table_grid_${thisClass.element_id}_wrapper .dataTables_length label`).addClass('m-0')
                        $(`#data_table_grid_${thisClass.element_id}_wrapper .dataTables_filter label input`).addClass('m-0')
                        $('.dataTables_paginate > .pagination').addClass('pagination-rounded');
                        $('[data-toggle="tooltip"]').tooltip();
                        if (thisClass.settings.extra_datatable_callback != null){
                            eval(thisClass.settings.extra_datatable_callback);
                        }
                    },
                    order: [thisClass.settings.order_by_col],
                    paging: true,
                    pageLength: thisClass.settings.page_length,
                    responsive: true,
                    fixedColumns: true,
                    lengthChange: true,
                    columnDefs: this.settings.columns_defs,
                    ajax: {
                        "url": thisClass.settings.url,
                        "data": function(d) {
                          try {
                            return {...d};
                          }catch (e){
                            return {...d};
                          }
                        },
                        "type": thisClass.settings.url_req_method,
                        "headers": {
                            "X-CSRFToken": thisClass.settings.csrf_token,
                        },
                    },
                    // dom: `<"row align-items-center" <'col-2' l> <'custom-btns col-10 text-right'> >rt<"row" <'col-6' i> <'col-6' p> >`
                    dom: `<"row align-items-center" <'col-2' l> <'col-4' f>  <'custom-btns col-6 text-right' > >rt<"row" <'col-6' i> <'col-6' p> >`,
                    // dom: "<'#id.row' l>frtip"
                })
            } catch (e) {
                console.log(e);
            }
            $.each(this.settings.columns_defs, function (i, e) {
                try {
                    if ('post_render_function' in e){
                        if (e.post_render_function){
                            eval(e.post_render_function)
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            })

            $(`#data_table_grid_${this.element_id} tbody`).on('click', 'td.dtr-control', function () {
                $('[data-toggle="tooltip"]').tooltip();
            })

            // Render Grid Btns
            try{
                if (Array.isArray(this.settings.custom_btn_list)) {
                    if (this.settings.custom_btn_list.length > 0){
                        $.each(this.settings.custom_btn_list, function(i, custom_btn_obj){
                            try{
                                if (typeof(custom_btn_obj) === "string"){
                                    if (custom_btn_obj in thisClass.default_btns){
                                        thisClass.CreateCustomBtn(thisClass.default_btns[custom_btn_obj], i)
                                    }
                                }else if (typeof(custom_btn_obj) === "object"){
                                    if ("type" in custom_btn_obj){
                                        thisClass.CreateCustomBtn($.extend(custom_btn_obj, thisClass.default_btns[custom_btn_obj.type]));
                                    }else {
                                        thisClass.CreateCustomBtn(custom_btn_obj, i)
                                    }
                                }
                            }catch(e){
                                console.log(e);
                            }
                        })
                    }
                }
            }catch(e){
                console.log(e)
            }

            // Enable Upload CSV Event
            try {
                if ($.inArray("upload_csv", this.settings.custom_btn_list) !== -1) {
                    $(`#data_table_grid_${thisClass.element_id}_wrapper .custom-btns`).prepend(`
                        <form encType="multipart/form-data" id="upload_csv_form" method="POST" noValidate=""
                              style="display: none;">
                            {% csrf_token %}
                            <input type="file" class="" name="upload_csv_input" id="upload_csv_input"
                                   style="display: none;"/>
                        </form>
                   `)
                    $(`#data_table_grid_${thisClass.element_id}_wrapper .custom-btns #upload_csv_btn`).on('click', function (e) {
                        $(this).blur();
                        e.preventDefault();
                        $(`#data_table_grid_${thisClass.element_id}_wrapper .custom-btns #upload_csv_input`).click();
                    })

                    $(`#data_table_grid_${thisClass.element_id}_wrapper .custom-btns #upload_csv_input`).on('change', function (e) {
                        let error_rendered = false;
                        try {
                            let f_obj = $(this).get(0).files[0]
                            let formData = new FormData($(`#data_table_grid_${thisClass.element_id}_wrapper .custom-btns #upload_csv_form`).get(0));
                            if (f_obj.name.match(/.(?:csv|xl)$/) || f_obj.name.match(/.(?:xlsx|xl)$/)) {
                                $.ajax({
                                    type: 'POST',
                                    beforeSend: function (xhr, settings) {
                                        xhr.setRequestHeader("X-CSRFToken", sessionCsrfToken);
                                    },
                                    data: formData,
                                    url: thisClass.settings.url.replace("datatable_data", "upload_csv"),
                                    contentType: false,
                                    processData: false,
                                    success: async function (data, textStatus, xhr) {
                                        let errors_csvs_keys = ['errors', 'unprocessed']
                                        if (data.success === true) {
                                            let error_exist = false
                                            if (data.data !== null) {
                                                $.each(errors_csvs_keys, async function (index, key) {
                                                    try {
                                                        if (`${key}_csv` in data.data) {
                                                            if (data.data[`${key}_csv`] !== null) {
                                                                error_exist = true
                                                                renderAlertMessage({
                                                                    'message_type': 'fail',
                                                                    'message_id': `upload_${thisClass.element_id}_csv_fail`,
                                                                    'message_content': `Upload failed in ${data.data[`${key}_count`]} rows. Check errors CSV!`
                                                                });
                                                                let is_success = await download_csv_report({
                                                                    'file_name': `upload_${thisClass.element_id}_${key}_csv`,
                                                                    'data': data.data[`${key}_csv`]
                                                                })
                                                            }
                                                        }
                                                    } catch (e) {
                                                        console.log(e)
                                                    }
                                                })
                                            }
                                            if (error_exist !== true) {
                                                renderAlertMessage({
                                                    'message_type': 'success',
                                                    'message_id': `upload_${thisClass.element_id}_csv_success`,
                                                    'message_content': 'CSV was Uploaded Successfully!'
                                                });
                                            }
                                        } else {
                                            if (error_rendered === false) {
                                                let error_text = 'Failed to Upload CSV'
                                                if (data.error !== null) {
                                                    error_text += `: ${data.error}`
                                                }
                                                renderAlertMessage({
                                                    'message_type': 'fail',
                                                    'message_id': `upload_${thisClass.element_id}_csv_fail`,
                                                    'message_content': error_text
                                                });
                                                error_rendered = true;
                                            }
                                        }
                                        thisClass.datatable.ajax.reload();
                                    },
                                    error: function (jqXHR, textStatus, errorThrown) {
                                        if (error_rendered === false) {
                                            let responseData = jqXHR.responseText;
                                            try {
                                                responseData = $.parseJSON(responseData);
                                            } catch (e) {
                                            }
                                            let error_text = 'Failed to Upload CSV'
                                            try {
                                                if (responseData.error !== null) {
                                                    error_text = `${error_text}: ${responseData.error}`;
                                                }
                                            } catch (e) {
                                            }
                                            renderAlertMessage({
                                                'message_type': 'fail',
                                                'message_id': `upload_${thisClass.element_id}_csv_fail`,
                                                'message_content': error_text
                                            });
                                            error_rendered = true;
                                        }
                                    },
                                    complete: function (jqXHR, textStatus) {
                                        $(`#data_table_grid_${thisClass.element_id}_wrapper .custom-btns #upload_csv_input`).val(null);
                                    },
                                })
                            } else {
                                renderAlertMessage({
                                    'message_type': 'fail',
                                    'message_id': `upload_${thisClass.element_id}_csv_fail`,
                                    'message_content': 'You must upload CSV ot XLSX file!'
                                });
                                $(`#data_table_grid_${thisClass.element_id}_wrapper .custom-btns #upload_csv_input`).val(null);
                            }
                        } catch (e) {
                            console.log(e);
                            if (error_rendered === false) {
                                renderAlertMessage({
                                    'message_type': 'fail',
                                    'message_id': `upload_${thisClass.element_id}_csv_fail`,
                                    'message_content': 'Failed to Upload CSV'
                                });
                                error_rendered = true;
                            }
                            $(`#data_table_grid_${thisClass.element_id}_wrapper .custom-btns #upload_csv_input`).val(null);
                        }
                    })
                }
            }catch(e){
                console.log(e);
            }

            // Enable Download CSV Template Event
            try {
                if ($.inArray("download_csv_template", this.settings.custom_btn_list) !== -1) {
                    $(`#data_table_grid_${thisClass.element_id}_wrapper .custom-btns #download_csv_template_btn`).on('click', function (e) {
                        $(this).blur();
                        e.preventDefault();
                        let error_rendered = false;
                        try {
                            $.ajax({
                                method: 'GET',
                                contentType: 'application/json; charset=utf-8',
                                beforeSend: function (xhr, settings) {
                                    xhr.setRequestHeader("X-CSRFToken", sessionCsrfToken);
                                },
                                dataType: "json",
                                async: true,
                                cache: false,
                                url: thisClass.settings.url.replace("datatable_data", "download_csv_template"),
                                success: async function (data, textStatus, xhr) {
                                    if (data.success === true) {
                                        let is_success = await download_csv_report({
                                            'file_name': `csv_${thisClass.element_id}_template`,
                                            'data': data.data
                                        })
                                        if (is_success === true) {
                                            renderAlertMessage({
                                                'message_type': 'success',
                                                'message_id': `download_${thisClass.element_id}_template_success`,
                                                'message_content': 'CSV Template was downloaded Successfully!'
                                            });
                                        } else {
                                            if (error_rendered === false) {
                                                renderAlertMessage({
                                                    'message_type': 'fail',
                                                    'message_id': `download_${thisClass.element_id}_template_fail`,
                                                    'message_content': 'Failed to Download CSV Template!'
                                                });
                                                error_rendered = true;
                                            }
                                        }
                                    } else {
                                        if (error_rendered === false) {
                                            let error_text = 'Failed to Download CSV Template'
                                            if (data.error !== null) {
                                                error_text += `: ${data.error}`
                                            }
                                            renderAlertMessage({
                                                'message_type': 'fail',
                                                'message_id': `download_${thisClass.element_id}_template_fail`,
                                                'message_content': error_text
                                            });
                                            error_rendered = true;
                                        }
                                    }
                                },
                                error: function (jqXHR, textStatus, errorThrown) {
                                    if (error_rendered === false) {
                                        let responseData = jqXHR.responseText;
                                        try {
                                            responseData = $.parseJSON(responseData);
                                        } catch (e) {
                                        }
                                        let error_text = 'Failed to Download CSV Template'
                                        if (responseData.error !== null) {
                                            error_text += `: ${responseData.error}`
                                        }
                                        renderAlertMessage({
                                            'message_type': 'fail',
                                            'message_id': `download_${thisClass.element_id}_template_fail`,
                                            'message_content': error_text
                                        });
                                        error_rendered = true;
                                    }
                                },
                            })
                        } catch (e) {
                            console.log(e);
                            if (error_rendered === false) {
                                renderAlertMessage({
                                    'message_type': 'fail',
                                    'message_id': `download_${thisClass.element_id}_template_fail`,
                                    'message_content': 'Failed to Download CSV Template!'
                                });
                                error_rendered = true;
                            }
                        }
                    })
                }
            }catch(e){
                console.log(e);
            }

            // Enable Download CSV Event
            try {
                if ($.inArray("download_csv", this.settings.custom_btn_list) !== -1) {
                    $(`#data_table_grid_${thisClass.element_id}_wrapper .custom-btns #download_csv_btn`).on('click', function (e) {
                        $(this).blur();
                        e.preventDefault();
                        let error_rendered = false;
                        let grid_search = "";
                        try {
                            grid_search = $(`#data_table_grid_${thisClass.element_id}_wrapper .dataTables_filter input`).val();
                        }catch(e){
                            console.log(e);
                        }
                        try {
                            $.ajax({
                                method: 'GET',
                                contentType: 'application/json; charset=utf-8',
                                beforeSend: function (xhr, settings) {
                                    xhr.setRequestHeader("X-CSRFToken", sessionCsrfToken);
                                },
                                dataType: "json",
                                async: true,
                                cache: false,
                                url: thisClass.datatable.ajax.url().replace("datatable_data", "download_csv") + `&grid_search=${grid_search}`,
                                success: async function (data, textStatus, xhr) {
                                    if (data.success === true) {
                                        let is_success = await download_csv_report({
                                            'file_name': `${thisClass.element_id}_data`,
                                            'data': data.data
                                        })
                                        if (is_success === true) {
                                            renderAlertMessage({
                                                'message_type': 'success',
                                                'message_id': `download_${thisClass.element_id}_csv_data_success`,
                                                'message_content': 'CSV Data was downloaded Successfully!'
                                            });
                                        } else {
                                            if (error_rendered === false) {
                                                renderAlertMessage({
                                                    'message_type': 'fail',
                                                    'message_id': `download_${thisClass.element_id}_csv_data_fail`,
                                                    'message_content': 'Failed to Download CSV!'
                                                });
                                                error_rendered = true;
                                            }
                                        }
                                    } else {
                                        if (error_rendered === false) {
                                            let error_text = 'Failed to Download CSV'
                                            if (data.error !== null) {
                                                error_text += `: ${data.error}`
                                            }
                                            renderAlertMessage({
                                                'message_type': 'fail',
                                                'message_id': `download_${thisClass.element_id}_csv_data_fail`,
                                                'message_content': error_text
                                            });
                                            error_rendered = true;
                                        }
                                    }
                                },
                                error: function (jqXHR, textStatus, errorThrown) {
                                    if (error_rendered === false) {
                                        let responseData = jqXHR.responseText;
                                        try {
                                            responseData = $.parseJSON(responseData);
                                        } catch (e) {
                                        }
                                        let error_text = 'Failed to Download CSV'
                                        if (responseData.error !== null) {
                                            error_text += `: ${responseData.error}`
                                        }
                                        renderAlertMessage({
                                            'message_type': 'fail',
                                            'message_id': `download_${thisClass.element_id}_csv_data_fail`,
                                            'message_content': error_text
                                        });
                                        error_rendered = true;
                                    }
                                },
                            })
                        } catch (e) {
                            console.log(e);
                            if (error_rendered === false) {
                                renderAlertMessage({
                                    'message_type': 'fail',
                                    'message_id': `download_${thisClass.element_id}_csv_data_fail`,
                                    'message_content': 'Failed to Download CSV!'
                                });
                                error_rendered = true;
                            }
                        }
                    })
                }
            }catch(e){
                console.log(e);
            }
        }
    }catch(e){
        console.log(e);
    }
};


DatatableGrid.prototype.InitDatatableFilter = function() {
    this.filter_instance = alithos_init_grid_filters(`data_table_grid_${this.element_id}`, this.datatable, this.settings.filters_list);
    try {
        this.filter_instance.load_filter();
    } catch (e) {
        console.log(e);
    }
}

DatatableGrid.prototype.CreateCustomBtn = function(custom_btn_obj, index) {
    const thisClass = this;
    let btn_has_perm = true
    try {
        if ("permission" in custom_btn_obj) {
            if (typeof(custom_btn_obj.permission) === "string"){
                if (custom_btn_obj.permission === "true" || custom_btn_obj.permission === "false" || custom_btn_obj.permission === "True" || custom_btn_obj.permission ==="False"){
                    btn_has_perm = JSON.parse(custom_btn_obj.permission.toLowerCase())
                }else if (custom_btn_obj.permission.includes('add') || custom_btn_obj.permission.includes('change') || custom_btn_obj.permission.includes('delete')) {
                    btn_has_perm = false;
                    if (user_perms !== null && Array.isArray(user_perms)) {
                        if ($.inArray(custom_btn_obj.permission, user_perms) !== -1 ) {
                            btn_has_perm = true;
                        }
                    }
                }
            }else if (typeof(custom_btn_obj.permission) === "boolean") {
                btn_has_perm = custom_btn_obj.permission
            }
        }
    }catch(e){
        console.log(e);
    }
    if (btn_has_perm === true) {
        let btn_href = "javascript:;"
        try {
            if ("href" in custom_btn_obj) {
                btn_href = custom_btn_obj.href
            }
        } catch (e) {
            console.log(e);
        }
        let btn_class = ""
        try {
            if ("btn_class" in custom_btn_obj) {
                btn_class = custom_btn_obj.btn_class
            }
        } catch (e) {
            console.log(e);
        }
        let btn_id = ""
        try {
            if ("btn_id" in custom_btn_obj) {
                btn_id = `id=${custom_btn_obj.btn_id}`;
            }
        } catch (e) {
            console.log(e);
        }
        let tooltip = ""
        try {
            if ("tooltip" in custom_btn_obj) {
                tooltip = `data-toggle="tooltip" data-placement="top" data-original-title="${custom_btn_obj.tooltip}"`
            }
        } catch (e) {
            console.log(e);
        }
        let btn_icon = "fas fa-plus"
        try {
            if ("btn_icon" in custom_btn_obj) {
                btn_icon = custom_btn_obj.btn_icon
            }
        } catch (e) {
            console.log(e);
        }
        let custom_btn_html = `<a href="${btn_href}" class="btn btn-primary waves-effect waves-light m-0 ${index !== 0? "mr-1": ""} ${btn_class}" ${btn_id} style="line-height: 1.4;" ${tooltip}><i class="${btn_icon}"></i></a>`
        $(`#data_table_grid_${thisClass.element_id}_wrapper .custom-btns`).prepend(custom_btn_html);
    }
}

DatatableGrid.prototype.RenderModal = function({
    btn_class = null,
    request_method = null,
    request_link= null,
    confirm_btn_class= "success",
    modal_content = "Are You Sure to commit this action?",
    modal_sub_content = "",
    modal_sub_content_class = "muted",
    modal_title = "Confirm",
    confirm_btn_label = "Confirm",
    success_message="Action Committed Successfully!",
    error_message="Failed to Commit Action!"
}) {
    let thisClass = this;
    try{
        if (btn_class !== null && request_link !== null && request_method !== null) {
            $(`#data_table_grid_${thisClass.element_id} tbody`).on('click', `.${btn_class}`, function (e) {
                e.preventDefault();
                let thisBtn = $(this);
                $(this).tooltip("hide");
                let confirmForm = `
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body" id='${thisClass.element_id}_${btn_class}_form_wrap'>
                                    <form class='form-horizontal'>
                                        <div class="form-group mb-0 justify-content-end row">
                                              <div class="col-12" style='text-align:left;'>
                                                <h5>${modal_content}</h5>
                                              </div>
                                              <div class="col-12" style='text-align:left;'>
                                                <label class="text-${modal_sub_content_class}">${modal_sub_content}</label>
                                              </div>
                                              <div class="col-12" style='text-align:right;'>
                                                  <button type="button" class="btn btn-${confirm_btn_class} waves-effect waves-light" id='confirm_${thisClass.element_id}_${btn_class}'>${confirm_btn_label}</button>
                                                  <button type="button" class="btn btn-secondary waves-effect waves-light" id='cancel_${thisClass.element_id}_${btn_class}'>Cancel</button>
                                              </div>
                                        </div>
                                    </form>
                                </div> <!-- end card-body -->
                            </div> <!-- end card-->
                        </div> <!-- end col -->
                    </div><!-- end row -->
                `;

                let confirmFormTitle =
                    `<div class="p-1 mb-0 pb-0">` +
                    `<h4 class="text-white text-primary">` +
                    `${modal_title}</h4>` +
                    `</div>`;

                swal.fire({
                    backdrop: false,
                    scrollbarPadding: true,
                    showCloseButton: true,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    showClass: {
                        popup: "swal2-noanimation",
                        backdrop: "swal2-noanimation",
                    },
                    hideClass: {
                        popup: "",
                        backdrop: "",
                    },
                    width: "40vw",
                    html: confirmForm,
                    title: confirmFormTitle,
                    onOpen: function () {
                        $(`#confirm_${thisClass.element_id}_${btn_class}`).on('click', function (e) {
                            let error_rendered = false;
                            try {
                                $.ajax({
                                    method: request_method,
                                    contentType: 'application/json; charset=utf-8',
                                    beforeSend: function (xhr, settings) {
                                        xhr.setRequestHeader("X-CSRFToken", sessionCsrfToken);
                                    },
                                    dataType: "json",
                                    async: true,
                                    cache: false,
                                    url: `${eval(request_link)}`,
                                    success: function (data, textStatus, xhr) {
                                        if ("success" in data){
                                            if (data['success'] === true){
                                                renderAlertMessage({
                                                    'message_type': 'success',
                                                    'message_id': `${thisClass.element_id}_${btn_class}_success`,
                                                    'message_content': success_message,
                                                });
                                                try {
                                                    thisClass.datatable.ajax.reload();
                                                    swal.close();
                                                } catch (e) {
                                                    console.log();
                                                }
                                            }else{
                                                let error_text = error_message;
                                                if (error_rendered === false) {
                                                    if (data['error'] !== null) {
                                                        error_text = data['error']
                                                    }
                                                    renderAlertMessage({
                                                        'message_type': 'fail',
                                                        'message_id': `${thisClass.element_id}_${btn_class}_fail`,
                                                        'message_content': error_text
                                                    });
                                                    error_rendered = true;
                                                }
                                            }
                                        }else {
                                            renderAlertMessage({
                                                'message_type': 'success',
                                                'message_id': `${thisClass.element_id}_${btn_class}_success`,
                                                'message_content': success_message,
                                            });
                                            try {
                                                thisClass.datatable.ajax.reload();
                                                swal.close();
                                            } catch (e) {
                                                console.log();
                                            }
                                        }
                                    },
                                    error: function (jqXHR, textStatus, errorThrown) {
                                        if (error_rendered === false) {
                                            let responseData = jqXHR.responseText;
                                            try {
                                                responseData = $.parseJSON(responseData);
                                            } catch (e) {
                                            }
                                            let error_text = error_message;
                                            try {
                                                if ('protected' in responseData) {
                                                    if (responseData.protected === true) {
                                                        error_text = responseData.result;
                                                    }
                                                }
                                            } catch (e) {
                                            }
                                            try {
                                                if ('error' in responseData) {
                                                    if (responseData.error !== null) {
                                                        error_text = responseData.error;
                                                    }
                                                }
                                            } catch (e) {
                                            }
                                            renderAlertMessage({
                                                'message_type': 'fail',
                                                'message_id': `${thisClass.element_id}_${btn_class}_fail`,
                                                'message_content': error_text
                                            });
                                            error_rendered = true;
                                        }
                                    },
                                    complete: function (jqXHR, textStatus) {
                                        swal.close();
                                    }
                                })
                            } catch (e) {
                                console.log(e);
                                if (error_rendered === false) {
                                    renderAlertMessage({
                                        'message_type': 'fail',
                                        'message_id': `${thisClass.element_id}_${btn_class}_fail`,
                                        'message_content': error_message,
                                    });
                                    error_rendered = true;
                                }
                                swal.close();
                            }
                        })

                        $(`#cancel_${thisClass.element_id}_${btn_class}`).unbind('click').bind('click', function (e) {
                            swal.close();
                        })
                    },
                    onClose: function () {
                        setTimeout(function () {
                            thisBtn.tooltip("hide");
                        }, 300)
                    }
                });
            });
        }
    } catch (e){
        console.log(e);
    }
}

$.fn.datatableGrid = function(options) {
    let instance = null
    this.each(function() {
        if (!$.data(this, 'datatableGrid')) {
            instance = $.data(this, 'datatableGrid', new DatatableGrid(this, options));
        }
    });
    return instance;
};

///////////////////////////////////// Filters /////////////////////////////////////////////////

function alithos_init_grid_filters(slug, dataTable, filter_fields){
    "use strict";
    let grid_filters_card = null;
    try{
        grid_filters_card = new GridFilters(
          {
              'div': `${slug}_filters`,
              'datatable': dataTable,
              'filter_fields': filter_fields,
              api_url_separator: "&",
              grid_id: `${slug}`,
              insight: slug,
          }
        );
    }catch (e) {
        console.log(e);
    }
    return grid_filters_card
}

class GridFilters{
    constructor({
        div: div,
        datatable: datatable,
        filter_fields: filter_fields,
        api_url_separator : api_url_separator,
        grid_id: grid_id,
        insight: insight = null,
    } = {}) {
        this.div = div;
        this.datatable = datatable;
        this.filter_fields = filter_fields;
        this.api_url_separator = api_url_separator;
        this.grid_id = grid_id;
        this.applied_filter = false;
        this.api_filter_params_url = '';
        this.insight = insight;

        let grid_search = $(`#${this.grid_id}_wrapper .dataTables_filter input`).val();
        let api_url_filter_params = this.datatable.ajax.url() + this.api_url_separator +`filter_params=` + JSON.stringify([]) + `&grid_search=${grid_search}`;

        // get filter expression form api url
        let filterResult = '';
        try{
            let n = api_url_filter_params.lastIndexOf('/');
            filterResult = api_url_filter_params.substring(n + 1);
        }catch(e){
            console.log(e)
        }
        this.api_filter_params_url = filterResult;
        this.original_api_url = this.datatable.ajax.url();

        this.render_filters_div();
        this.bind_filter_event();
    }

    render_filters_div(){
        let thisClass = this;
        let filters_html = ``;
        filters_html += `
            <div class="card-box mb-0 pt-1 pb-1">
                <div class="row">
                    <div class="col-xl-10">
                        <h4 class="filter_header mt-0 mb-0  pt-2">Filters</h4>
                    </div>
                    <div class="col-xl-2 justify-content-end">
                        <button id="${this.div}_collapse_btn" class="btn waves-effect" type="button" style="box-shadow: none !important;"><i class="fas fa-minus" style="color: #6c757d;"></i></button>
                    </div>
                </div>
                <div class="filter_container" style="display:block; transition: all .3s ease-in-out;">
                    <div class="row" id="${this.div}_wrap">
        `;
        filters_html += `</div>`;
        filters_html += `
            <div class="row justify-content-end mt-4 mb-2">
                <div class="col-12 d-flex justify-content-center">
                    <button type="button" class="btn btn-primary waves-effect mr-1" id="${this.div}_filter_btn" style="width:100%;">Apply</button>
                    <button type="button" class="btn btn-primary waves-effect" id="${this.div}_reset_btn" style="width:100%;">Reset</button>
                </div>
            </div>
        `;
        filters_html += `</div></div>`;
        $(`#${this.div}`).append(filters_html);
        $(`#${this.div}_collapse_btn`).on('click', function(event){
          $(`#${thisClass.div}`).toggleClass('col-xl-3');
          $(`#${thisClass.div}`).toggleClass('col-xl-1-custom-filter');
          $(`#${thisClass.div}`).toggleClass('collapsed');
          $(`#${thisClass.grid_id}_col`).toggleClass('col-xl-11-custom-filter');
          $(`#${thisClass.grid_id}_col`).toggleClass('col-9');
          $(this).children('i').toggleClass('fa-minus');
          $(this).children('i').toggleClass('fa-plus');
        });
        $.each(this.filter_fields, function(index, filter){
            try{
                if (filter != null){
                    if (filter.type === "dropdown"){
                        thisClass.render_dropdown_filter(filter);
                    }else if (filter.type === "range"){
                        thisClass.render_range_filter(filter);
                    }else if (filter.type === "range_date_date" || filter.type === "range_date_date_time"){
                        thisClass.render_range_date_filter(filter);
                    }else if (filter.type === "radio"){
                        thisClass.render_radio_filter(filter);
                    }else if (filter.type === "text_search"){
                        thisClass.render_text_search_filter(filter);
                    }
                }
            }catch(error){
                console.log(error);
            }
        });
    };

    render_dropdown_filter(filter){
        let thisClass = this;
        let html = ``;
        html += `
            <div id="${this.div}_${filter.data}_dropdown_filter" class="mt-2 col-12">
                <div class="row">
                    <h5 class="col-xl-8">${filter.label}</h5>
                    <div class="col-xl-4 d-flex justify-content-end align-items-center">
                        <a style="cursor:pointer;" class="clean_filter_field" id="${this.div}_${filter.data}_dropdown_clear" data-filter-field="${filter.data}" data-filter-type="${filter.type}">clear</a>
                    </div>
                </div>
                <div class="row">
                <div class="col-xl-12">
                    <select class="form-control select2-multiple" data-toggle="select2" multiple="multiple" id="${this.div}_${filter.data}_dropdown" style="width:100%">
        `;
        let options = filter.options
        try { options = JSON.parse(options);} catch (e) {}
        $.each(options, function(index, option){
            if ("option_value" in option && "option_label" in option){
                html += `<option value="${option.option_value}">${option.option_label}</option>`;
            }else if ("value" in option && "label" in option){
                html += `<option value="${option.value}">${option.label}</option>`;
            }
        });
        html += `</select>
            </div></div></div>`;
        $(`#${this.div}_wrap`).append(html);
        $(`#${this.div}_${filter.data}_dropdown`).select2({
        });
        $(`#${this.div}_${filter.data}_dropdown_clear`).on("click", function(event){
            let filterField = $(this).data("filter-field");
            $(`#${thisClass.div}_${filterField}_dropdown`).val(null).trigger('change');
        });
    };

    render_range_filter(filter){
        let thisClass = this;
        let html = ``;
        html += `
            <div id="${this.div}_${filter.data}_range_filter" class="mt-2 col-12">
                <div class="row">
                    <h5 class="col-xl-8">${filter.label}</h5>
                    <div class="col-xl-4 d-flex justify-content-end align-items-center">
                        <a style="cursor:pointer;" class="clean_filter_field" id="${this.div}_${filter.data}_range_clear" data-filter-field="${filter.data}" data-filter-type="${filter.type}">clear</a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-6 form-group mb-0">
                        <input class="form-control" id="${this.div}_${filter.data}_min" type="number" placeholder="From" style="width:100%">
                    </div>
                    <div class="col-xl-6 form-group mb-0">
                        <input class="form-control" id="${this.div}_${filter.data}_max" type="number" placeholder="To" style="width:100%">
                    </div>
                </div>
            </div>
        `;
        $(`#${this.div}_wrap`).append(html);
        $(`#${this.div}_${filter.data}_range_clear`).on("click", function(event){
            var filterField = $(this).data("filter-field");
            $(`#${thisClass.div}_${filterField}_min`).val(null);
            $(`#${thisClass.div}_${filterField}_max`).val(null);
        });
    };

    render_range_date_filter(filter){
        let thisClass = this;
        let html = ``;
        html += `
            <div id="${this.div}_${filter.data}_range_date_filter" class="mt-2 col-12">
                <div class="row">
                    <h5 class="col-xl-8">${filter.label}</h5>
                    <div class="col-xl-4 d-flex justify-content-end align-items-center">
                        <a style="cursor:pointer;" class="clean_filter_field" id="${this.div}_${filter.data}_range_date_clear" data-filter-field="${filter.data}" data-filter-type="${filter.type}">clear</a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-6 form-group mb-0">
                        <input class="form-control" id="${this.div}_${filter.data}_date_min" type="text" placeholder="From" style="width:100%">
                    </div>
                    <div class="col-xl-6 form-group mb-0">
                        <input class="form-control" id="${this.div}_${filter.data}_date_max" type="text" placeholder="To" style="width:100%">
                    </div>
                </div>
            </div>
        `;
        $(`#${this.div}_wrap`).append(html);

        let flatpickr_config = {
            altInput: true,
            altFormat: "F j, Y",
            dateFormat: "Y-m-d",
            mood: "single",
        }
        window[this.div + '_' + filter.data + '_date_picker_min'] = $(`#${this.div}_${filter.data}_date_min`).flatpickr(flatpickr_config);
        window[this.div + '_' + filter.data + '_date_picker_max'] = $(`#${this.div}_${filter.data}_date_max`).flatpickr(flatpickr_config);
        $(`#${this.div}_${filter.data}_range_date_clear`).on("click", function(event){
            let filterField = $(this).data("filter-field");
            window[thisClass.div + '_' + filterField + '_date_picker_min'].clear();
            window[thisClass.div + '_' + filterField + '_date_picker_max'].clear();
        });
    };

    render_radio_filter(filter){
        let thisClass = this;
        let html = ``;
        html += `
            <div id="${this.div}_${filter.data}_radio_filter" class="mt-2 col-12">
                <div class="row">
                    <h5 class="col-xl-8">${filter.label}</h5>
                    <div class="col-xl-4 d-flex justify-content-end align-items-center">
                        <a style="cursor:pointer;" class="clean_filter_field" id="${this.div}_${filter.data}_radio_clear" data-filter-field="${filter.data}" data-filter-type="${filter.type}">clear</a>
                    </div>
                </div>
                <ul class="nav nav-pills navtab-bg nav-justified" id="pills-tab" role="tablist" id="${thisClass}_${filter.data}_radio" style="width:100%">
        `;
        let options = filter.options;
        try { options = JSON.parse(options);} catch (e) {}

        let selected_option_rendered = false;
        this[`${filter.data}_radio_default_value`] = null;

        $.each(options, function(index, option){
            let active_link = "";
            let selected_link = "false";
            try {
                if ('selected' in option){
                    if (option.selected === true){
                        if (selected_option_rendered === false) {
                            active_link = "active";
                            selected_link = "true";
                            selected_option_rendered = true;
                            thisClass[`${filter.data}_radio_default_value`] = option.value;
                        }
                    }
                }
            } catch (e) {
                console.log(e);
            }
            if ("option_value" in option && "option_label" in option){
                html += `
                    <li class="nav-item">
                        <a class="nav-link ${active_link} mr-0 ml-0 d-flex justify-content-center align-items-center ${thisClass.div}_${filter.data}_radio_option" data-init-selected=${selected_link} data-toggle="pill" style="cursor:pointer; height: 100%" role="tab"
                         aria-selected="${selected_link}" data-value="${option.option_value}">${option.option_label}</a>
                    </li>
                `;
            }else if ("value" in option && "label" in option){
                html += `
                    <li class="nav-item">
                        <a class="nav-link ${active_link} mr-0 ml-0 d-flex justify-content-center align-items-center ${thisClass.div}_${filter.data}_radio_option" data-init-selected=${selected_link} data-toggle="pill" style="cursor:pointer; height: 100%" role="tab"
                         aria-selected="${selected_link}" data-value="${option.value}">${option.label}</a>
                    </li>
                `;
            }

        });
        let active_link_all = "";
        let selected_link_all = "false";
        if (selected_option_rendered === false){
            active_link_all = "active";
            selected_link_all = "true";
        }
        html += `
                    <li class="nav-item">
                        <a class="nav-link ${active_link_all} mr-0 ml-0 d-flex justify-content-center align-items-center ${this.div}_${filter.data}_radio_option" data-init-selected=${selected_link_all} data-toggle="pill" style="cursor:pointer; height: 100%" role="tab"
                         aria-selected="${selected_link_all}" data-value=null>All</a>
                    </li>
                `;
        html += `</ul>
            </div>
        `;
        $(`#${this.div}_wrap`).append(html);
        $(`#${this.div}_${filter.data}_radio_clear`).on("click", function(event){
            let filterField = $(this).data("filter-field");
            $(`.${thisClass.div}_${filter.data}_radio_option`).each(function(index, element){
                try {
                    if ($(this).data('init-selected') === true) {
                        $(this).attr("aria-selected", "true");
                        $(this).addClass("active");
                    } else {
                        $(this).attr("aria-selected", "false");
                        $(this).removeClass("active");
                    }
                } catch (e) {
                    console.log(e);
                }
            });
        });
    };

    render_text_search_filter(filter){
        let thisClass = this;
        let html = ``;
        html += `
            <div id="${this.div}_${filter.data}_text_search_filter" class="mt-2 col-12">
                <div class="row">
                    <h5 class="col-xl-8">${filter.label}</h5>
                    <div class="col-xl-4 d-flex justify-content-end align-items-center">
                        <a style="cursor:pointer;" class="clean_filter_field" id="${this.div}_${filter.data}_text_search_clear" data-filter-field="${filter.data}" data-filter-type="${filter.type}">clear</a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 form-group mb-0">
                        <input type="text" id="${this.div}_${filter.data}_text_search" class="form-control" placeholder="Search ${filter.label}" style="width:100%">
                    </div>
                </div>
            </div>
        `;
        $(`#${this.div}_wrap`).append(html);
        $(`#${this.div}_${filter.data}_text_search_clear`).on("click", function(event){
            let filterField = $(this).data("filter-field");
            $(`#${thisClass.div}_${filterField}_text_search`).val(null);
        });
    };

    bind_filter_event(){
        let thisClass = this;
        try{
            $(`#${this.div}_filter_btn`).on('click', function(event){
                thisClass.load_filter();
            });
        }catch(e){
            console.log(e);
        }

        try{
            $(`#${this.div}_reset_btn`).on('click', function(event){
                $(`#${thisClass.insight}_loading`).css({
                    'display': 'inline-flex',
                });
                setTimeout(function(){
                    $(`#${thisClass.insight}_loading`).css({
                        'display': 'none',
                    });
                }, 60000);
                $.each(thisClass.filter_fields, function(index, filter){
                    if (filter != null){
                        if (filter.type === "dropdown"){
                            $(`#${thisClass.div}_${filter.data}_dropdown`).val(null).trigger('change');
                        }else if (filter.type === "range"){
                            $(`#${thisClass.div}_${filter.data}_min`).val(null);
                            $(`#${thisClass.div}_${filter.data}_max`).val(null);
                        }else if (filter.type === "range_date" || filter.type === "range_date_date" || filter.type === "range_date_date_time"){
                            window[thisClass.div + '_' + filter.data + '_date_picker_min'].clear();
                            window[thisClass.div + '_' + filter.data + '_date_picker_max'].clear();
                        }else if (filter.type === "radio"){
                            $(`.${thisClass.div}_${filter.data}_radio_option`).each(function(index, element){
                                try {
                                    if (thisClass[`${filter.data}_radio_default_value`] === $(this).data('value')) {
                                        $(this).attr("aria-selected", "true");
                                        $(this).addClass("active");
                                    } else {
                                        $(this).attr("aria-selected", "false");
                                        $(this).removeClass("active");
                                    }
                                } catch (e) {
                                    console.log(e);
                                }
                            });
                        }else if (filter.type === "text_search"){
                            $(`#${thisClass.div}_${filter.data}_text_search`).val(null);
                        }
                    }
                });
                thisClass.load_filter();
                thisClass.applied_filter = false;
            });
        }catch(error){
            console.log(error);
        }
    };

    load_filter(){
        let thisClass = this;
        let filter_params = [];
        $(`#${thisClass.insight}_loading`).css({
            'display': 'inline-flex',
        });
        setTimeout(function(){
            $(`#${thisClass.insight}_loading`).css({
                'display': 'none',
            });
        }, 60000);
        $.each(thisClass.filter_fields, function(index, filter){
            let filter_obj = {};
            try{
                if (filter != null){
                    if (filter.type === "dropdown"){
                        let filterValues = $(`#${thisClass.div}_${filter.data}_dropdown`).val();
                        filter_obj["value"]= filterValues;
                        filter_obj["data"]= filter.data;
                        filter_obj["type"]= filter.type;
                    }else if (filter.type === "range"){
                        if($.trim($(`#${thisClass.div}_${filter.data}_min`).val()) !== '' || $.trim($(`#${thisClass.div}_${filter.data}_max`).val()) !== ''){
                            if ($.trim($(`#${thisClass.div}_${filter.data}_min`).val()) !== ''){
                                // let filter_format = "decimal";
                                // if ("format" in filter){
                                //     filter_format = filter.format
                                // }
                                // let filterValueMin = formatData($(`#${thisClass.div}_${filter.data}_min`).val(), filter_format);
                                let filterValueMin = $(`#${thisClass.div}_${filter.data}_min`).val()
                                filter_obj["value_min"]= filterValueMin;
                            }
                            if ($.trim($(`#${thisClass.div}_${filter.data}_max`).val()) !== ''){
                                // let filter_format = "decimal";
                                // if ("format" in filter){
                                //     filter_format = filter.format
                                // }
                                // let filterValueMax = formatData($(`#${thisClass.div}_${filter.data}_max`).val(), filter_format);
                                let filterValueMax = $(`#${thisClass.div}_${filter.data}_max`).val()
                                filter_obj["value_max"]= filterValueMax;
                            }
                            filter_obj["data"]= filter.data;
                            filter_obj["type"]= filter.type;
                        };
                    }else if (filter.type === "range_date" || filter.type === "range_date_date" || filter.type === "range_date_date_time"){
                        if ($(`#${thisClass.div}_${filter.data}_date_min`).val() != null || $(`#${thisClass.div}_${filter.data}_date_max`).val() != null){
                            if ($(`#${thisClass.div}_${filter.data}_date_min`).val() != null && $.trim($(`#${thisClass.div}_${filter.data}_date_min`).val()) !== ''){
                                let filterValueDateMin = $(`#${thisClass.div}_${filter.data}_date_min`).val();
                                filter_obj["value_date_min"] = moment.utc(filterValueDateMin).toISOString().split('.')[0]+"Z";
                            }
                            if ($(`#${thisClass.div}_${filter.data}_date_max`).val() != null && $.trim($(`#${thisClass.div}_${filter.data}_date_max`).val()) != ''){
                                let filterValueDateMax = $(`#${thisClass.div}_${filter.data}_date_max`).val();
                                filter_obj["value_date_max"] = moment.utc(filterValueDateMax).toISOString().split('.')[0]+"Z";
                            }
                            filter_obj["data"]= filter.data;
                            filter_obj["type"]= filter.type;
                        }
                    }else if (filter.type === "radio"){
                        $(`.${thisClass.div}_${filter.data}_radio_option`).each(function(index, element){
                            if ($(this).attr("aria-selected") === "true" && $(this).hasClass("active")){
                                if ($(this).data("value") != null){
                                    if ($(this).data("value") != "null"){
                                        let filterValue = $(this).data("value");
                                        try {
                                            if (filterValue === true){
                                                filterValue = "True";
                                            }else if (filterValue === false){
                                                filterValue = "False";
                                            }
                                        } catch (e) {
                                            console.log(e);
                                        }
                                        filter_obj["value"] = filterValue;
                                        filter_obj["data"]= filter.data;
                                        filter_obj["type"]= filter.type;
                                    }
                                }
                            }
                        });
                    }else if (filter.type === "text_search"){
                        if($.trim($(`#${thisClass.div}_${filter.data}_text_search`).val()) !== ''){
                            let filterValue = $(`#${thisClass.div}_${filter.data}_text_search`).val();
                            filter_obj["value"]= filterValue;
                            filter_obj["data"]= filter.data;
                            filter_obj["type"]= filter.type;
                        }
                    }
                }
            }catch(error){
                console.log(error);
                return;
            }
            if (!jQuery.isEmptyObject(filter_obj)){
                filter_params.push(filter_obj)
            }
        });
        thisClass.filter_params = filter_params;

        let grid_search = $(`#${thisClass.grid_id}_wrapper .dataTables_filter input`).val();
        let api_url_filter_params = thisClass.original_api_url + thisClass.api_url_separator +`filter_params=` + JSON.stringify(filter_params) + `&grid_search=${grid_search}`;

        // get filter expression form api url
        let filterResult = '';
        try{
            let n = api_url_filter_params.lastIndexOf('/');
            filterResult = api_url_filter_params.substring(n + 1);
        }catch(e){
            console.log(e);
        }
        thisClass.api_filter_params_url = filterResult;
        thisClass.datatable.clear();
        thisClass.datatable.ajax.url(api_url_filter_params).load();
        thisClass.applied_filter = true;
    };
}

let data_table_render_date = function (data, type, row) {
    "use strict";
    if (data != null) {
        return moment.utc(data).format("MMM D, YYYY");
    }
};

let data_table_render_currency = function (data, type, row) {
    "use strict";
    if (data != null){
      return `${formatData(data, 'currency')}`;
    }
};

let data_table_render_int = function (data, type, row) {
    "use strict";
    if (data != null){
      return `${formatData(data, 'int')}`;
    }
};

let data_table_render_decimal = function (data, type, row) {
    "use strict";
    if (data != null){
      return `${formatData(data, 'decimal')}`;
    }
};

let data_table_render_boolean = function (data, type, row) {
    return render_boolean_icon(data)
};

let data_table_render_truncate_paragraph = function (data, type, row) {
    "use strict";
    if (data != null){
      return `<p class="truncate m-0">${data}</p>`;
    }
};
